import { render, staticRenderFns } from "./tabBar.vue?vue&type=template&id=42b56b84"
import script from "./tabBar.vue?vue&type=script&lang=js"
export * from "./tabBar.vue?vue&type=script&lang=js"
import style0 from "./tabBar.vue?vue&type=style&index=0&id=42b56b84&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports